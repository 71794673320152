import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed",
  style: {"background-image":"url('media/illustrations/sketchy-1/14.png')"}
}
const _hoisted_2 = { class: "d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode("begin::Content"),
    _createElementVNode("div", _hoisted_2, [
      _createCommentVNode("begin::Logo"),
      _cache[0] || (_cache[0] = _createElementVNode("a", {
        href: "#",
        class: "mb-12"
      }, [
        _createElementVNode("img", {
          alt: "Logo",
          src: "media/logos/bblogo.png",
          class: "h-80px"
        })
      ], -1 /* HOISTED */)),
      _createCommentVNode("end::Logo"),
      _createVNode(_component_router_view)
    ]),
    _createCommentVNode("end::Content"),
    _createCommentVNode("begin::Footer"),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-flex flex-center flex-column-auto p-10" }, [
      _createCommentVNode("begin::Links"),
      _createCommentVNode(" <div class=\"d-flex align-items-center fw-bold fs-6\">\r\n        <a href=\"#\" class=\"text-muted text-hover-primary px-2\">About</a>\r\n\r\n        <a href=\"#\" class=\"text-muted text-hover-primary px-2\">Contact</a>\r\n\r\n        <a href=\"#\" class=\"text-muted text-hover-primary px-2\">Contact Us</a>\r\n      </div> "),
      _createCommentVNode("end::Links")
    ], -1 /* HOISTED */)),
    _createCommentVNode("end::Footer")
  ]))
}